
  import { Component, Vue } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { PurchaseOrder } from '@/entities/purchase'
  import { mapGetters } from 'vuex'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import dayjs from 'dayjs'

@Component({
  computed: {
    ...mapGetters('app', ['system']),
  },
})
  export default class consignmentPDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  documentType: Record<string, any>
  document: Record<string, any>
  template: Record<string, any>
  disabledPdf = true
  fields: Record<string, any>

  system!: string

  async mounted () {
    const { item: { id } } = this
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })

    this.documentType = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { name: { _eq: 'consignment_contract' } },
    }))[0]

    this.document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        id_process_record: { _eq: this.purchaseOrder.id },
        id_document_type: { _eq: this.documentType.id },
      },
    }))[0]

    this.disabledPdf = this.purchaseOrder.acquisitionType.name !== 'consignment' || !this.document
  }

  async generatePDF () {
    this.loading = true

    // Revisa si existe un documento asociado a la consignacion
    const fileParameter = (await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { name: { _eq: 'consignment_contract' } },
    }))[0]
    const fileProcess = (await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        id_file_parameter: { _eq: fileParameter.id },
        id_process_record: { _eq: this.purchaseOrder.id },
      },
    }))[0]

    if (fileParameter && fileProcess?.file) {
      window.open(fileProcess?.file?.uri)
      this.loading = false
      return
    }

    // Si no hay documento, crea uno nuevo
    this.template = this.documentType.template

    this.purchaseOrder.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id: this.purchaseOrder.negotiation.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal.stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { deals: { id: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } } },
    })

    await this.getFields()

    const pdfContent = this.$refs.pdfContent
    const element = pdfContent instanceof HTMLElement ? pdfContent : (pdfContent as Vue).$el as HTMLElement

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a4')
    this.setFont(doc)

    // Coordenadas iniciales
    const x = 10
    const y = 20

    doc.setLineWidth(400)

    // Llenar el PDF con el contenido
    await this.page1(doc, y, x)

    // Convertir el contenido del HTML a texto en el PDF
    this.addHtmlContent(doc, element, x, y)
  }

  async getFields () {
    const company = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    }))[0]
    const companyAddress = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: company.id } },
    }))[0]
    const { address } = companyAddress

    const chassisSerialComponent = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: {
        slug: { _eq: 'chassis_serial' },
      },
    }))[0]

    const chassisSerial = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        id_component: { _eq: chassisSerialComponent.id },
        id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id },
      },
    }))[0]

    const seller = this.document.interveners.filter(person => person.field.name === 'seller')[0].person
    const paymentOrder = (await this.fetchData({
      query: { name: 'find', model: 'PaymentOrder' },
      filter: { id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
    }))[0]
    const ticketPaymentOrderItems = paymentOrder.items.filter(item => item.processExpense.expense.name === 'traffic_ticket_payment')
    const carPaymentOrderItem = paymentOrder.items.filter(item => item.processExpense.expense.name === 'auto_payment')[0]

    const sellerAccount = carPaymentOrderItem.recipients[0].payments[0].recipientAccount
    const paymentMethod = carPaymentOrderItem.recipients[0].payments[0].type

    let ticketsCost = 0
    ticketPaymentOrderItems.forEach(item => ticketsCost += item.amount)

    const consignmentDays = this.document.metadata.consignment_period?.days || 2

    const companyAccounts = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    }))[0]

    const companyAccount = (await this.fetchData({
      query: { name: 'find', model: 'PersonAccount' },
      filter: { id: { _eq: companyAccounts.config.consignment.id_financial_account_income } },
    }))[0]

    const accountPerson = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: {
        accounts: {
          id: { _eq: companyAccount.id },
        },
      },
    }))[0]

    const utcDiff = dayjs().utcOffset()

    this.fields = {
      city: `${address.city.name}, región ${address.city.region.name}`.toUpperCase(),
      start_contract: 'las ' + this.document.createdAt.add(utcDiff, 'minute').format('HH:mm, DD/MM/YYYY'),
      auto: `${this.document.metadata.auto} año ${this.document.metadata.year} patente ${this.document.metadata.ppu}`,
      inspection: this.purchaseOrder.negotiation.inspection.id,
      chassis_serial: chassisSerial.value,
      mileage: fixAmountFormatted(this.document.metadata.mileage),
      company_name: `${company.companyName}, rol ${company.uid}`.toUpperCase(),
      company_address: `${address.streetType.description} ${address.streetName} ${address.streetNumber}`.toUpperCase(),
      company_account: companyAccount ? `${companyAccount.bankData.accountNumber}, ${companyAccount.bankData.bank.alias}`.toUpperCase() : 'NO INFORMADO',
      company_person_account_name: (accountPerson.companyName || `${accountPerson.firstName} ${accountPerson.surname}`).toUpperCase(),
      company_person_account_uid: accountPerson.uid,
      company_email: company.email.personal || company.email.work || 'NO INFORMADO',
      seller_name: `${seller.firstName.trim()}${seller.secondName ? ' ' : ''}${seller.secondName?.trim() || ''} ${seller.surname.trim()}${seller.secondSurname ? ' ' : ''}${seller.secondSurname?.trim() || ''}`.toUpperCase(),
      seller_uid: seller.uid,
      seller_account: (sellerAccount !== null ? `al banco ${sellerAccount?.bankData?.bank?.alias}, cuenta N° ${sellerAccount?.bankData?.accountNumber || ''} de` : 'a'),
      seller_email: seller.email.personal || seller.email.work || 'NO INFORMADO',
      agreed_price: fixPrice(this.purchaseOrder.agreedAmount),
      traffic_ticket_payment: fixPrice(ticketsCost),
      consignment_period: consignmentDays,
      consignment_expiracy_date: this.purchaseOrder.createdAt.add(consignmentDays, 'day').format('DD/MM/YYYY'),
      early_withdrawal_penalty: fixPrice(this.document.metadata.early_withdrawal_penalty.amount),
      non_withdrawal_cost: fixPrice(this.document.metadata.non_withdrawal_cost.amount),
      withdrawal_period: this.document.metadata.withdrawal_period?.days + ' días',
      payment_method: paymentMethod.description,
    }
  }

  replaceVariable (text, variableName, value) {
    return text.split(`$${variableName}$`).join(value).split('\'').join('"')
  }

  findVariableNames (text) {
    const variables = []
    text.split(' ').forEach(word => {
      const splitted = word.split('')
      if (splitted[0] === '$') {
        variables.push(word.split('$')[1])
      }
    })
    return variables
  }

  addIntroduction (doc, x, y) {
    const { paragraphs } = this.template

    paragraphs.forEach(paragraph => {
      const variables = this.findVariableNames(paragraph)
      let newText = paragraph

      variables.forEach(variable => {
        newText = this.replaceVariable(newText, variable, this.fields[variable])
      })

      y = this.addParagraph(doc, x, y, newText, 187, false)
    })

    return y
  }

  async addList (doc, x, y) {
    const { list } = this.template

    for (const [index, item] of list.entries()) {
      const variables = this.findVariableNames(item.text)
      let newText = item.text
        .split(' y en las condiciones indicadas en el informe de inspección N° $inspection$')
        .join(this.fields.inspection ? ' y en las condiciones indicadas en el informe de inspección N° $inspection$' : '')
        .split(', se descontará el valor de multa $traffic_ticket_payment$')
        .join(this.fields.traffic_ticket_payment !== '$0' ? ', se descontará el valor de multa $traffic_ticket_payment$' : '')

      variables.forEach(variable => {
        newText = this.replaceVariable(newText, variable, this.fields[variable])
      })

      newText = newText
        .split(' ,').join(',')
        .split(' .').join('. ')

      if (y > doc.internal.pageSize.getHeight() - 60) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc)
      }

      this.addBoldText(doc, x + 4, y, `${index + 1}. `)

      // Aquí ajustamos el margen izquierdo y el ancho máximo
      y = this.applyTextWithStyles(doc, x + 12, y, `|b|${item.title}|/b| ${newText}`) + 3
    }

    return y + 20
  }

  addClosure (doc: jsPDF, x: number, y: number): number {
    const { closure } = this.template
    y = this.addParagraph(doc, x, y, closure, 187, false)
    return y
  }

  addSignSection (doc: jsPDF, y) {
    const declaration = 'Firma y acepta las condiciones'
    const clientName = this.purchaseOrder.negotiation.inspection.deal.lead.client.fullName
    const clientUid = this.purchaseOrder.negotiation.inspection.deal.lead.client.uid

    let height = (y + doc.internal.pageSize.getHeight() - 30) / 2

    doc.setDrawColor('#0033A5')
    doc.line(60, height, doc.internal.pageSize.getWidth() - 60, height)
    height += 5
    doc.text(declaration, this.getCenterWidth(doc, declaration), height)
    height += 5
    doc.setFont(undefined, 'bold')
    doc.text(clientName, this.getCenterWidth(doc, clientName), height)
    height += 5
    doc.text(clientUid, this.getCenterWidth(doc, clientUid), height)
    height += 7
    return height
  }

  async addClosingSection (doc: jsPDF) {
    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })
    this.addClosing(doc, [
        'Asesor comercial:',
        `${executive.person.firstName} ${executive.person.surname}`,
        `Tel: ${executive.person.phoneWork || 'No informado'}`,
        executive.person.email.work || 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    await this.addClosingSection(doc)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, this.template.title) + 3
    y = this.addIntroduction(doc, x, y)
    y = await this.addList(doc, x, y)
    y = this.addClosure(doc, x, y)
    this.addSignSection(doc, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`consignacion ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.fullName} ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  findComponentInfo (component, inspection) {
    const matchingInspectedComponent = inspection.inspectedComponents?.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )
    if (!matchingInspectedComponent) return
    const date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

    const name = matchingInspectedComponent.findInspectionParameterByOrder(1).value

    return { date, name }
  }

  findComponentBySlug (inspectionComponents, slug) {
    return inspectionComponents?.find(component => component.slug === slug)
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
